import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import 'react-responsive-carousel/lib/styles/carousel.min.css';

//langues
//token//  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  ////https://www.creative-sols.com/
import './i18next'

const app = (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);
//document.getElementsByTagName('html')[0].setAttribute("dir", "ltr");
ReactDOM.render(app, document.getElementById("root"));
serviceWorker.unregister();
