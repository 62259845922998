import React from "react";

//Special
const PageComingSoon = React.lazy(() =>
  import("./pages/Pages/Special/PageComingSoon")
);
const PageComingSoon2 = React.lazy(() =>
  import("./pages/Pages/Special/PageComingSoon2")
);
const Course = React.lazy(() => import("./pages/Course/index"));

const PageError = React.lazy(() => import("./pages/Pages/Special/PageError"));
const PageThankYou = React.lazy(() =>
  import("./pages/Pages/Special/PageThankYou")
);
const PageMaintenance = React.lazy(() =>
  import("./pages/Pages/Special/PageMaintenance")
);

const IndexclassicSaas = React.lazy(() => import("./pages/Classic Saas/index"));
const PageAboutusTwo = React.lazy(() => import("./pages/Pages/PageAboutusTwo"));

//Utility
const PagePrivacy = React.lazy(() =>
  import("./pages/Pages/Utility/PagePrivacy")
);
const PageTerms = React.lazy(() => import("./pages/Pages/Utility/PageTerms"));
//Contact
const PageContactOne = React.lazy(() =>
  import("./pages/Pages/Contact/PageContactOne")
);
//nqraa art page
const nqraaArt = React.lazy(()=> import("./components/NqraaArth/GeoInfoService"))
const nqraaDronService = React.lazy(()=> import("./components/NqraaArth/DronService"))
const webgisService = React.lazy(()=> import("./components/NqraaArth/WebGis"))
const DiskReview = React.lazy(()=> import("./components/NqraaArth/DiskReview"))
//Contact
const PageStatred = React.lazy(() =>
  import("./pages/Pages/Started/index")
);

const comingsoon =  React.lazy(()=> import("./pages/Course/ComingSoon"))
const Services = React.lazy(() => import("./pages/Services/index"));






const routes = [
  //Special Pages
  {
    path: "/page-comingsoon",
    component: PageComingSoon,
    isWithoutLayout: true,
  },
  {
    path: "/page-comingsoon2",
    component: PageComingSoon2,
    isWithoutLayout: true,
  },
  { path: "/page-error", component: PageError, isWithoutLayout: true },
  { path: "/page-thankyou", component: PageThankYou, isWithoutLayout: true },
  {
    path: "/page-maintenance",
    component: PageMaintenance,
    isWithoutLayout: true,
  },
  //Utility
  { path: "/page-terms", component: PageTerms, isTopbarDark: true },
  { path: "/page-privacy", component: PagePrivacy, isTopbarDark: true },
  { path: "/page-started", component: PageStatred, isTopbarDark: true },
  { path: "/page-aboutus", component: PageAboutusTwo, isTopbarDark: true },
  //Page Contact
  { path: "/page-contact", component: PageContactOne, isTopbarDark: true },
  //Index Main
  { path: "/nqraacc", component: IndexclassicSaas, isTopbarDark: true },
  { path: "/index-course", component: Course },

  { path: "/", component: Services, isTopbarDark: true },
   //nqraa art link
   {path:"/geoinformation", component:nqraaArt,isTopbarDark: true},
   {path:"/dronservice", component:nqraaDronService,isTopbarDark: true},
   {path:"/webgisservice",component:webgisService ,isTopbarDark:true},
   {path:"/diskreview",component:DiskReview ,isTopbarDark:true},
   //cominsoon
   {path:"/comingsoon",component:comingsoon ,isTopbarDark:true},
];

export default routes;
